import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OutsourcingComponent } from './Outsourcing/outsourcing/outsourcing.component';

const routes: Routes = [
  {path: 'outsourcing', component: OutsourcingComponent},
  {path: '', redirectTo:'outsourcing', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
