import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class OutsourcingService {

  token: string;

  constructor(private http: HttpClient) {
    this.getToken()
      .subscribe((data) => {
        this.token = data.access_token;
      })
  }

  // USANDO O LAMBDA DE ACESSO
  // getToken() : Observable<any> {
  //   return this.http.get('https://t1u3g1tbhk.execute-api.eu-west-1.amazonaws.com/devstage');
  // }

  // USANDO DIRETAMENTE O ENDPOINT QUE O CLAYTON CRIOU
  // AMBIENTE DE ACEITE
  /*
  getToken() : Observable<any> {
   debugger
   return this.http.post('https://api.integration.devtest.aws.scania.com/token/v1','grant_type=client_credentials&client_secret=MztfJXAPrDBniKcxbZJUd0OHOKwa&client_id=OwGXkXFONZwFb1fn0R4y2ZGjTzAa',{
     headers: new HttpHeaders({
       'Content-Type':'application/x-www-form-urlencoded'
     })
   })
 }*/

  // AMBIENTE DE PRODUCAO Descontinuado esse processo de autentificação
  /*getToken() : Observable<any> {
    return this.http.post('https://api.integration.prod.aws.scania.com/token/v1','grant_type=client_credentials&client_secret=60A01EcJ62j2AX6SaRSjCvZXWb0a&client_id=wTKi4W8lOf0v6rOQkfN7vwjJbqIa',{
      headers: new HttpHeaders({
        'Content-Type':'application/x-www-form-urlencoded'
      })
    })
  }
  */

  // AMBIENTE DE ACEITE
  /*
   postJira(apontamentosXML : any) : Observable<any> {
     return this.http.post('https://api.integration.devtest.aws.scania.com/entrytime/v1?op=AddWorklog',apontamentosXML,{
       headers: {
         'Content-Type':'text/xml',
         'Authorization':'Bearer ' + this.token,
       },
       responseType: 'text'
     });
   }*/

  // AMBIENTE DE PRODUCAO USANDO FEDERETION 
  getToken(): Observable<any> {
    return this.http
      .post('https://fg.ciam.prod.aws.scania.com/auth/realms/scania/protocol/openid-connect/token', 'grant_type=client_credentials', {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': 'Basic aW1wb3J0YXJhcG9udGFtZW50b3NfY2xpZW50OjcyOTNjMjMyLWU2MGItNDA4ZC1iMTk2LTY4MDRmMzAwNjA5OQ=='
        })
      })
  }


  // AMBIENTE DE PRODUCAO
  //  postJira(apontamentosXML : any) : Observable<any> {
  //   return this.http.post('https://api.integration.prod.aws.scania.com/entrytime/v1?op=AddWorklog',apontamentosXML,{
  //     headers: {
  //       'Content-Type':'text/xml',
  //       'Authorization':'Bearer ' + this.token,
  //     },
  //     responseType: 'text'
  //   });
  // }

  postJira(apontamentosXML: any): Observable<any> {
    console.log(environment.production);
    return this.http.post('https://fit.br.scania.com/ws/jira.asmx?op=AddWorklog', apontamentosXML, {
      headers: {
        'Content-Type': 'text/xml'
      },
      responseType: 'text'
    });
  }

  ///AMBIENTE PARA RODA LOCAL
  // postJira(apontamentosXML: any): Observable<any> {
  //     //Ambiente local
  //     return this.http.post('http://localhost:58495/Jira.asmx/AddWorklog', apontamentosXML, { responseType: 'text' });
  // }

}
