import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-outsourcing-modal',
  templateUrl: './outsourcing-modal.component.html',
  styleUrls: ['./outsourcing-modal.component.css']
})
export class OutsourcingModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {  }

  @Input() title : string;
  @Input() showErrors : boolean;
  @Input() errors : string[];

  @Output() closeModal = new EventEmitter();

  close() {
    this.closeModal.emit();
  }

}
