import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as XLSX from 'ts-xlsx';
import { worklogAttribute } from '../worklogAttribute';
import { worklog } from '../worklog';
import { OutsourcingService } from '../outsourcing.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OutsourcingModalComponent } from 'src/app/Shared/outsourcing-modal/outsourcing-modal.component';
import { ResponseOut } from 'src/app/Shared/response';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-outsourcing',
  templateUrl: './outsourcing.component.html',
  styleUrls: ['./outsourcing.component.css']
})
export class OutsourcingComponent implements OnInit {

  @ViewChild(OutsourcingModalComponent) child: OutsourcingModalComponent;
  @ViewChild('myInput') childInputVar: ElementRef;

  @Input() selectFile: File = null;

  validFormat: boolean = true;
  arrayBuffer: any;
  apontamentos: any[];
  lsApontamentos: worklog[] = [];
  modalTitle: string = '';
  openModalOutsourcing: boolean = false;
  result: number = 0;
  showSuccess: boolean = false;
  showError: boolean = false;
  errorsList: string[];
  testearray = ['teste1', 'teste2', 'teste3'];
  readOnce: boolean = false;

  xmlRequest: string = '';

  xmlHeader: string =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">' +
    '<soap:Body>' +
    '<AddWorklog xmlns="http://tempuri.org/">' +
    '<apontamentos>'
    ;

  xmlBody: string = '';

  xmlFooter: string =
    '</apontamentos>' +
    '</AddWorklog>' +
    '</soap:Body>' +
    '</soap:Envelope>'
    ;

  onFileSelected(event: any) {
    this.selectFile = null;
    if (this.childInputVar.nativeElement.value != "") {
      if (event.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || event.target.files[0].type == 'application/vnd.ms-excel') {
        this.selectFile = event.target.files[0];
        this.validFormat = true;
        this.readExcel();
      }
    }
    else {
      this.validFormat = false;
    }
  }

  enviarApontamento() {
    if (this.validFormat == true && this.selectFile != null) {
      this.apontamentos.forEach(element => {

        let remainer: number = element.Hours % 1;
        var totalSeconds: number;

        if (remainer != 0) {
          switch (true) {
            case (remainer < 0.5):
              this.result = element.Hours - remainer + 0.5;
              break;
            case (remainer >= 0.51):
              this.result = element.Hours - remainer + 1;
              break;
            case (remainer == 0.5):
              this.result = element.Hours;
              break;
          }
        }

        totalSeconds = element.Hours * 3600;

        if (remainer != 0) {
          totalSeconds = this.result * 3600;
        }


        let worklogAttribute: worklogAttribute = {
          _Activity_: {
            value: element.Activity
          }
        };

        let date: string = element['Date Started'];
        let day = date.substr(0, 2);
        let month = date.substr(3, 2);
        let year = date.substr(6, 4);

        let lowerWorker = element.Worker.toLowerCase();

        let worklog: worklog = {
          worker: lowerWorker,
          comment: element.Comment,
          started: year + '-' + month + '-' + day,
          timeSpentSeconds: totalSeconds,
          originTaskId: element.Issue,
          attributes: worklogAttribute,
          includeNonWorkingDays: false,
        };
        this.lsApontamentos.push(worklog);

      });

      this.postOnJira(this.lsApontamentos);

    }
    else {
      console.log(this.validFormat);
    }
  }

  postOnJira(apontamentos: worklog[]) {

    apontamentos.forEach((value) => {
      let xmlapontamento: string =
        '<OutsourcingModel>' +
        '<worker>' + value.worker + '</worker>' +
        '<comment>' + value.comment + '</comment>' +
        '<started>' + value.started + '</started>' +
        '<timeSpentSeconds>' + value.timeSpentSeconds + '</timeSpentSeconds>' +
        '<originTaskId>' + value.originTaskId + '</originTaskId>' +
        '<attributes>' +
        '<_Activity_ xsi:nil="true">' +
        '<value>' + value.attributes._Activity_.value + '</value>' +
        '</_Activity_>' +
        '</attributes>' +
        '</OutsourcingModel>';

      this.xmlBody += xmlapontamento;
    })

    this.xmlRequest = this.xmlHeader + this.xmlBody + this.xmlFooter;

    //console.log(this.xmlRequest);

    this.ngxService.start();
    this.outsourcingService.postJira(this.xmlRequest)
      .subscribe(
        (data: any) => {
          this.ngxService.stop();
          this.checkResponse(data);
        },
        (err) => {
          console.log(err);
          this.ngxService.stop();
          this.showErrorAlert();
        }
      )
  }

  readExcel(): void {
    if (this.childInputVar.nativeElement.value != 0) {
      this.ngxService.start();
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        this.apontamentos = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      }

      fileReader.readAsArrayBuffer(this.selectFile);
      this.ngxService.stop();
    }
  }

  showSuccessAlert(): void {
    this.showSuccess = true;
    setTimeout(() => this.showSuccess = false, 5000);
  }

  showErrorAlert(): void {
    this.showError = true;
    setTimeout(() => this.showError = false, 5000);
  }

  checkResponse(responseString: string) {
    const parser = new DOMParser();
    const xmlText = parser.parseFromString(responseString, 'text/html');
    const obj = this.ngxXml2jsonService.xmlToJson(xmlText);

    console.log(obj);
    console.log(environment.production);

    if (obj['HTML']['BODY']['SOAP:ENVELOPE']['SOAP:BODY']['ADDWORKLOGRESPONSE']['ADDWORKLOGRESULT']['OUTSOURCINGMODEL']['CONTENT'].length > 0) {
      //retorno do produção o objeto é diferente
      this.showSuccessAlert();
      this.childInputVar.nativeElement.value = "";
      this.lsApontamentos = [];
      this.xmlBody = "";
    }
    else if (obj['HTML']['BODY']['OUTSOURCINGMODEL']['CONTENT'].length > 0) {
      //Retorno do local
      this.showSuccessAlert();
      this.childInputVar.nativeElement.value = "";
      this.lsApontamentos = [];
      this.xmlBody = "";
    } else {
      this.modalTitle = 'Errors';
      console.log(obj);
      this.openResponseModal(obj['HTML']['BODY']['SOAP:ENVELOPE']['SOAP:BODY']['ADDWORKLOGRESPONSE']['ADDWORKLOGRESULT']['OUTSOURCINGMODEL']['MESSAGES']);
      this.childInputVar.nativeElement.value = "";
      this.lsApontamentos = [];
      this.xmlBody = "";
    }

    // RECEIVING NORMAL XML FROM WEBSERVICE
    // if(obj['HTML']['BODY']['SOAP:ENVELOPE']['SOAP:BODY']['ADDWORKLOGRESPONSE']['ADDWORKLOGRESULT']['OUTSOURCINGMODEL']['HASERRORS'] == 'false'){
    //   this.showSuccessAlert();
    //   this.childInputVar.nativeElement.value = "";
    //   this.lsApontamentos = [];
    //   this.xmlBody = "";

    // } else {
    //   this.modalTitle = 'Errors';
    //   console.log(obj['HTML']['BODY']['SOAP:ENVELOPE']['SOAP:BODY']['ADDWORKLOGRESPONSE']['ADDWORKLOGRESULT']['OUTSOURCINGMODEL']['MESSAGES']);
    //   this.openResponseModal(obj['HTML']['BODY']['SOAP:ENVELOPE']['SOAP:BODY']['ADDWORKLOGRESPONSE']['ADDWORKLOGRESULT']['OUTSOURCINGMODEL']['MESSAGES']);
    //   this.childInputVar.nativeElement.value = "";
    //   this.lsApontamentos = [];
    //   this.xmlBody = "";
    // }

  }

  closeOutsourcingModal() {
    this.openModalOutsourcing = false;
    this.childInputVar.nativeElement.value = "";
  }

  openResponseModal(messages: string[]) {
    this.openModalOutsourcing = true;
    this.errorsList = [];
    if (Array.isArray(messages)) {
      this.errorsList = messages;
    } else {
      this.errorsList.push(messages);
    }


  }

  constructor(private outsourcingService: OutsourcingService, private ngxService: NgxUiLoaderService, private ngxXml2jsonService: NgxXml2jsonService) { }

  ngOnInit() { }

}
