import { Component } from '@angular/core';

declare var window: any; // Create a variable for use below

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CSharpUtils';

  constructor() {
    window.CorporateUi.baseComponents();
  }
}
